import * as React from 'react';

import { Translation } from 'react-i18next';

export interface TranslateProps {
	label: string;
}

export const Translate: React.FunctionComponent<TranslateProps> = (props) => {
	return <Translation>{(t,options) => t(props.label, {...options})}</Translation>;
};
