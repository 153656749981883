import * as React from 'react';
import { useLocation } from "@reach/router"
import { useTranslation } from 'react-i18next';

export const useRouteChangeLanguageEffect = () => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation(); 

  React.useEffect(() => {
    const pathnameLocale = pathname.split('/')?.[1];

    if(i18n.language !== pathnameLocale) {
      i18n.changeLanguage(pathnameLocale)
    }
  }, [pathname, i18n]);
}