import * as React from 'react';

export type PLFlagProps = React.SVGAttributes<SVGElement>;

export const FlagPL: React.FC<PLFlagProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Flag of Poland" viewBox="0 0 16 10" className={className}>
      <rect width="16" height="10" fill="#fff" />
      <rect width="16" height="5" fill="#dc143c" y="5" />
    </svg>
  )
}