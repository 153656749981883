import * as React from 'react';
import { useLanguage } from 'utils/useLanguage';
import { Link as RouterLink } from 'gatsby';
import { Location } from '@reach/router';
import { Flag } from './Flag';
import styled from 'styled-components';

const StyledFlag = styled(Flag)`
  width: 32px;
  height: auto;
  border: 1px solid black;
  margin-right: ${props => props.theme.spacing(2)};
`

const StyledRouterLink = styled(RouterLink)`
  margin-right: auto;
  display: flex;
  align-items: center;
`

const LanguageChanger: React.FC = () => {
  const currentLanguage = useLanguage();
  const redirectToLanguage = currentLanguage === 'en' ? 'pl' : 'en';
  const renderFlagCode = redirectToLanguage === 'en' ? 'uk' : 'pl';

  return (
    <Location>
      {({ location: { pathname } }) => (
        <StyledRouterLink to={pathname.replace(`/${currentLanguage}`, `${redirectToLanguage}`)}>
          <StyledFlag code={renderFlagCode} />
        </StyledRouterLink>
      )}
    </Location>
  )
}

const MemoizedLanguageChanger = React.memo(LanguageChanger);

export { MemoizedLanguageChanger as LanguageChanger };