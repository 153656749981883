import * as React from 'react';
import { css } from 'styled-components';
import { Typography } from 'components/Typography';
import { useMatch } from '@reach/router';
import { Link as RouterLink } from 'gatsby';

export interface LinkProps {
  to: string;
  language: string;
  className?: string;
}

const linkClassName = css`
  color: ${props => props.theme.colorPalette.textSecondary};
  padding: ${props => props.theme.spacing(0, 1)};
  min-width: 96px;
  display: flex;
  justify-content: center;

  &:hover {
    color: ${props => props.theme.colorPalette.primary};
    text-decoration: underline;
  }
`;

const HomePageLink: React.FC<LinkProps> = ({ to, className, children }) => {
  const onClickCallback = React.useCallback(() => {
    document.getElementById(to)?.scrollIntoView({
      behavior: 'smooth'
    });
  }, [to])

  return (
    <a
      onClick={onClickCallback}
      className={className}
    >
      <Typography variant='button'>
        {children}
      </Typography>
    </a>
  )
}

const SitePageLink: React.FC<LinkProps> = ({ to, language, className, children }) => {

  return (
    <RouterLink
      to={`/${language}/#${to}`}
      className={className}
    >
      <Typography variant='button'>
        {children}
      </Typography>
    </RouterLink>
  )
}

export const Link: React.FC<LinkProps> = (props) => {
  const isHomePage = !!useMatch(`/${props.language}`);

  return isHomePage 
    ? <HomePageLink {...props} css={linkClassName} /> 
    : <SitePageLink {...props} css={linkClassName} />
}
