import * as React from 'react';
import styled from 'styled-components'
import { Link } from './Link';
import { useLanguage } from 'utils/useLanguage';
import { Translate } from 'components/Translate';
import { LanguageChanger } from 'components/LanguageChanger';

const Wrapper = styled.div`
  position: fixed;
  @media (max-width: 720px) {
    position: sticky;
  }
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${props => props.theme.spacing(3, 2)};
  background-color: white;
  flex-wrap: wrap;
`

export const TopMenu: React.FC = () => {
  const language = useLanguage();

  return (
    <Wrapper>
      <LanguageChanger />
      <Link language={language} to='about_me'>
        <Translate label='topMenu.aboutMe' />
      </Link>
      <Link language={language} to='it_projects'>
        <Translate label='topMenu.itProjects' />
      </Link>
      <Link language={language} to='design_projects'>
        <Translate label='topMenu.designProjects' />
      </Link>
    </Wrapper>
  )
}
