import React from 'react';
import Layout from './src/Layout';
import { ThemeProvider } from './src/theming/ThemeProvider';
import { I18nextProvider } from 'react-i18next';
import i18next from './src/i18n/config';

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </I18nextProvider>
  );
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      {element}
    </Layout>
  )
}
