import * as React from 'react';
import { FlagUK, FlagPL } from './CountryFlags';

export interface FlagProps extends React.SVGAttributes<SVGElement> {
  code: string;
}

const mapping: Record<string, any> = {
  pl: FlagPL,
  uk: FlagUK
}

export const Flag: React.FC<FlagProps> = ({ code, ...svgProps }) => {
  const Flag = mapping[code];
  return Flag ? <Flag {...svgProps} /> : null;
}