import i18next from 'i18next';
import { getUserLanguage } from 'utils/getUserLanguage';

const userLanguage = getUserLanguage();

i18next.init({
  lng: userLanguage || 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translations: require('../locales/en.json')
    },
    pl: {
      translations: require('../locales/pl.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react
  },
  react: {
    wait: true,
  },
});

i18next.languages = ['zh-Hant', 'en'];

export default i18next;