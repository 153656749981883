import * as React from "react"
import styled, { createGlobalStyle } from "styled-components";
import { TopMenu } from "components/TopMenu";
import { useRouteChangeLanguageEffect } from "utils/useRouteChangeLanguageEffect";

const GlobalCssStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
  }
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`

const AppContent = styled.div`
  height: 100vh;
`

const MainLayout: React.FC = ({ children }) => {
  useRouteChangeLanguageEffect();
  
  return (
    <>
      <GlobalCssStyles />
      <TopMenu />
      <AppContent>
        {children}
      </AppContent>
    </>
  );
}

export default MainLayout;
